import extend from 'deep-extend';
import Swiper from 'swiper';

export default class {

    constructor(elem, options) {
        const defaults= {
            swiperContainer: '.swiper-container',
            swiperOptions: {}
        };

        this.options = extend({}, defaults, options);

        this.swiper = null;
        this.thumbSwiper = null;
        this.$elem = elem;

        /*
        Init the thumbnail swiper first
         */
        if(this.options.thumbnails){
            this.$thumbSwiperContainer = this.$elem.querySelector(this.options.thumbnails.swiperContainer);
            this.thumbsSwiper = new Swiper(this.$thumbSwiperContainer, this.options.thumbnails.swiperOptions)
        }

        /*
        Init main swiper
         */
        this.$swiperContainer = this.$elem.querySelector(this.options.swiperContainer);
        let defaultSwiperOptions = this.options.swiperOptions;

        if(this.options.thumbnails){

            defaultSwiperOptions = Object.assign({}, defaultSwiperOptions, {
                thumbs: {
                    swiper: this.thumbsSwiper
                }
            });
        }

        this.swiper = new Swiper(this.$swiperContainer, defaultSwiperOptions);
    }
}

