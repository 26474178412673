import extend from 'deep-extend';
import Swiper from 'swiper';

export default class {

    constructor(elem, options) {
        const defaults = {

            masterSwiper: '.master-swiper',
            masterSwiperOptions: {
                simulateTouch: false,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                pagination: {
                    el: '.master-swiper-pagination',
                },
            },

            categorySwiper: '.category-swiper',
            categorySwiperOptions: {
                loop: true,
                pagination: {
                    el: '.category-swiper-pagination',
                },

                breakpointsInverse: true,
                breakpoints: {
                    992: {
                        slidesPerView: 2,
                        spaceBetween: 24
                    }
                }
            },


            defaultTabIndex: 0,
            tabControls: '.tab-controls',
            tabButton: 'button',
            btnTabPrev: '.btn-tab-prev',
            btnTabNext: '.btn-tab-next',
            btnSlidePrev: '.btn-slide-prev',
            btnSlideNext: '.btn-slide-next',

        };

        this.options = extend({}, defaults, options);

        /*
        State
         */
        this.activeTabIndex = this.options.defaultTabIndex;
        this.categorySwipers = [];


        /*
        Define nodes / elements
        */
        this.$elem = elem;

        //Swiper elements
        this.$masterSwiper = this.$elem.querySelector(this.options.masterSwiper);
        this.$categorySwiper = this.$elem.querySelectorAll(this.options.categorySwiper);

        //Tab control elements
        this.$tabControls = this.$elem.querySelector(this.options.tabControls);
        this.$tabButtons = this.$tabControls.querySelectorAll(this.options.tabButton);

        //Next / prev elements -- mobile, tab next and prev
        this.$btnTabPrev = this.$elem.querySelector(this.options.btnTabPrev);
        this.$btnTabNext = this.$elem.querySelector(this.options.btnTabNext);

        //Next / prev elements -- desktop, active slide next and prev
        
		this.$btnSlidePrevs = this.$elem.querySelectorAll(this.options.btnSlidePrev);
        this.$btnSlideNexts = this.$elem.querySelectorAll(this.options.btnSlideNext);

        /*
        Init Master Swiper
         */
        this.masterSwiper = new Swiper(this.$masterSwiper, this.options.masterSwiperOptions);

        /*
        Function inits
        */
        this.initCategorySwipers();
        this.bindEvents();
    }

    setActiveTab(newTabIndex) {

         // console.log(this.activeTabIndex, newTabIndex);


        if(this.activeTabIndex !== newTabIndex){

            /*
            Set locally stored tab index value
             */
            this.activeTabIndex = newTabIndex;

            /*
            Adjust classes on buttons
            */
            this.$tabButtons.forEach(function (elem, index) {
                if(index === newTabIndex){
                    elem.classList.add('active');
                } else {
                    elem.classList.remove('active');
                }
            });

            /*
            Target the 'master' swiper, and go to target slide
             */
            this.masterSwiper.slideTo(newTabIndex);


        } else {
            // console.log('already at ', this.activeTabIndex, newTabIndex)
        }
    }

    initCategorySwipers(){
        const self = this;

        this.$categorySwiper.forEach(function (elem, index) {
            let newSwiper = new Swiper(elem, self.options.categorySwiperOptions);
            self.categorySwipers.push(newSwiper);
        });
    }

    bindEvents(){
        /*
        Event binding
         */
        const self = this;

        /*
        Bind tab control events
         */
        this.$tabButtons.forEach(function (elem, index) {

            /*
            Find the first, add an active class
             */
            if (index === self.options.defaultTabIndex){
                elem.classList.add('active');
            }
            elem.addEventListener('click', ()=>{
                self.setActiveTab(index);
            });
        });

        /*
        Bind mobile tab next / prev buttons
            Assume no looping functionality for now;
            if required looping logic will need to be added
            to below
         */
        this.$btnTabPrev.addEventListener('click', ()=>{
            let newTabIndex = 2; // last Tab
            if(this.activeTabIndex !== 0){
                newTabIndex = this.activeTabIndex - 1;
                self.setActiveTab(newTabIndex);
            }
            else{
                self.setActiveTab(newTabIndex)
            }
        });

        this.$btnTabNext.addEventListener('click', ()=>{
            let newTabIndex = 0;
            if(this.activeTabIndex !== 2){
                newTabIndex = this.activeTabIndex + 1;
                self.setActiveTab(newTabIndex);
            }
            else{
                self.setActiveTab(newTabIndex);
            }
        });

        /*
        Bind desktop slide next / previous buttons
            These directly control the 'active' category swiper;
            No logic required, any looping functionality will
            be dictated by the swiper options
         */
        
		this.$btnSlidePrevs.forEach(function (elem, index) {
		  elem.addEventListener('click', ()=>{

		   let activeSwiper = self.categorySwipers[self.activeTabIndex];
           activeSwiper.slidePrev();
		  });
        });
        

        this.$btnSlideNexts.forEach(function (elem, index) {
            elem.addEventListener('click', ()=>{
  
             let activeSwiper = self.categorySwipers[self.activeTabIndex];
             activeSwiper.slideNext();
            });
          });
    }
}

