import extend from 'deep-extend';
import Swiper from 'swiper';

export default class {

    constructor(elem, options) {
        const defaults= {
            imgBlock: '.image-block',
            contentBlock: '.content-block',
            transitionSpeed: 450,
            autoplayDelay: 5000,
            swiperContainer: '.swiper-container',
            swiperOptions: {}
        };

        this.options = extend({}, defaults, options);

        this.$elem = elem;
        this.imgSwiper = null;
        this.contentSwiper = null;

        /*
        Module elements
         */
        this.$imgBlock = this.$elem.querySelector(this.options.imgBlock);
        this.$imgSwiperContainer = this.$imgBlock.querySelector(this.options.swiperContainer);

        this.$contentBlock = this.$elem.querySelector(this.options.contentBlock);
        this.$contentSwiperContainer = this.$contentBlock.querySelector(this.options.swiperContainer);


        /*
        To do  -- swap this with SSM
         */
        if(window.innerWidth < 992){
            this.initImgSwiper(true);
            this.initContentSwiper(true);
        } else {
            this.initImgSwiper(false);
            this.initContentSwiper(false);
        }
    }

    initImgSwiper(isMobile){
        let swiperConfig = {
            simulateTouch: false,
            loop: true,
            speed: this.options.transitionSpeed,
            delay: this.options.autoplayDelay,
            pagination: {
                el: '.fraction',
                type: 'fraction',
            },
        };

        if(isMobile === false){
            swiperConfig = {
                effect: 'fade',
                simulateTouch: false,
                speed: this.options.transitionSpeed,
                delay: this.options.autoplayDelay,
                loop: true,
                fadeEffect: {
                    crossFade: true
                },
                pagination: {
                    el: '.fraction',
                    type: 'fraction',
                },
            }
        }

        this.imgSwiper = new Swiper(this.$imgSwiperContainer, swiperConfig);
    }

    initContentSwiper(isMobile){

        let swiperConfig = {
            simulateTouch: true,
            loop: true,
            speed: this.options.transitionSpeed,
            controller: {
                control: this.imgSwiper
            },

            navigation: {
                nextEl: '.btn-next',
                prevEl: '.btn-prev',
            },

            pagination: {
                el: '.bullets',
            },
            autoplay: {
                delay: this.options.autoplayDelay
            }
        };

        if(isMobile === false){
            swiperConfig = {
                simulateTouch: false,
                loop: true,
                effect: 'fade',
                speed: this.options.transitionSpeed,
                fadeEffect: {
                    crossFade: true
                },
                controller: {
                    control: this.imgSwiper
                },

                navigation: {
                    nextEl: '.btn-next',
                    prevEl: '.btn-prev',
                },

                pagination: {
                    el: '.bullets',
                },
                autoplay: {
                    delay: this.options.autoplayDelay
                }
            }
        }
        this.contentSwiper = new Swiper(this.$contentSwiperContainer, swiperConfig);
    }

}

