export default class {

    constructor(options, statemanager) {
        const defaults = {
            btnOffCanvas: '.btn-offcanvas'
        };

        this.$elem = document.querySelector('#global-header');
        this.$body = document.body;

        if (!this.$elem){
            return;
        }

        /*
        Define nodes / elements
         */
        this.$btnOffCanvas = this.$elem.querySelector(defaults.btnOffCanvas);

        /*
        Event binding
         */
        this.bindEvents();

    }

    toggleNavClasses(){

        //Toggle button
        if(this.$btnOffCanvas.classList.contains('is-active')){
            this.$btnOffCanvas.classList.remove('is-active');
        }  else {
            this.$btnOffCanvas.classList.add('is-active');
        }

        //Toggle body element; used for fixing nav and overflow
        if(this.$body.classList.contains('nav-open')){
            this.$body.classList.remove('nav-open');
        }  else {
            this.$body.classList.add('nav-open');
        }
    }

    minimizeHeader(){
        this.$elem.classList.add('minimized');
    }

    maximizeHeader(){
        if(this.$elem.classList.contains('minimized')) {
            this.$elem.classList.remove('minimized');
        }
    }

    bindEvents(){
        let self = this;
        this.$btnOffCanvas.addEventListener('click', () =>{
            Mjolnir.Components.GlobalNav.toggleNav();
            this.toggleNavClasses();
        });

        document.addEventListener('scroll', function(e) {
            if(window.scrollY > 0){
                self.minimizeHeader();
            } else {
                self.maximizeHeader();
            }
        });
    }
}
