import extend from 'deep-extend';

export default class {

    constructor(elem, options) {
        const defaults = {
            accordionItem: '.accordion-item',
            btnAccordionTrigger: '.btn-accordion-trigger',
            accordionContent: '.accordion-content',
            animateSpeed: 300,
            openFirst: false,
            allowMultipleOpen: false
        };

        if (!elem){
            return;
        }

        this.options = extend({}, defaults, options);

        /*
         Define nodes / elements
         */
        this.$elem = elem;
        this.$accordionItems = this.$elem.querySelectorAll(this.options.accordionItem);
        this.activeAccordion = null;
        this.activeBtn = null;

        /*
        Function inits
         */
        this.initAccordionItems();
    }

    closeAccordionItem(targetElem, contentElem, clickedBtn){
        if (targetElem.classList.contains('active')) {
            let targetContent = targetElem.querySelectorAll(this.options.accordionContent)[0];

            targetElem.classList.remove('active');
            targetContent.setAttribute('style', 'height: 0px');

            this.activeAccordion = null;
        }

        console.log(clickedBtn);
        if(clickedBtn){
            clickedBtn.classList.remove('active');
            this.activeBtn = null;
        }
    }

    openAccordionItem(parentElem, contentElem, clickedBtn){
        let openHeight = contentElem.getAttribute('data-open-height');
        contentElem.setAttribute('style', 'height: '+openHeight+'px');
        parentElem.classList.add('active');
        if(clickedBtn){
            clickedBtn.classList.add('active');
            this.activeBtn = clickedBtn;
        }
        this.activeAccordion = parentElem;
    }

    toggleAccordionItem(parentElem, contentElem, clickedBtn){

        if (this.options.allowMultipleOpen === false) {

            if (this.activeAccordion !== null){

                let isTargetActive = parentElem.isEqualNode(this.activeAccordion);
                if (isTargetActive === true) {

                    this.closeAccordionItem(parentElem, clickedBtn);

                } else {

                    this.closeAccordionItem(this.activeAccordion, this.activeBtn);
                    this.openAccordionItem(parentElem, contentElem, clickedBtn);
                }
            } else {
                this.openAccordionItem(parentElem, contentElem, clickedBtn);
            }
        } else {

            if (parentElem.classList.contains('active')) {
                this.closeAccordionItem(parentElem, contentElem, clickedBtn);

            } else {
                this.openAccordionItem(parentElem, contentElem, clickedBtn);
            }
        }
    }

    initAccordionItems(){
        let self = this;
        this.$accordionItems.forEach(function (elem, index) {
            let $accordionContent = elem.querySelectorAll(self.options.accordionContent)[0];
            let $btnAccordionTrigger = elem.querySelectorAll(self.options.btnAccordionTrigger)[0];

            if ($accordionContent){

                /*
                Set default 'closed' state, by defining inline styles and attributes for 'open' state
                 */
                let contentHeight = $accordionContent.scrollHeight;

                $accordionContent.setAttribute('data-open-height', contentHeight);
                $accordionContent.setAttribute('style', 'height: 0px');

                /*
                Event binding for each item
                 */
                $btnAccordionTrigger.addEventListener('click', ()=>{
                    self.toggleAccordionItem(elem, $accordionContent, $btnAccordionTrigger)
                });

            } else {
                return;
            }
        });

        if (this.options.openFirst === true){
            let $firstItem = this.$accordionItems[0];
            let $firstItemContent = $firstItem.querySelectorAll(self.options.accordionContent)[0];
            this.openAccordionItem($firstItem, $firstItemContent)
        }
    }
}
