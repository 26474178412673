/*
Utilities, Polyfills and shims
 */
import picturefill from 'picturefill';
import svg4everybody from 'svg4everybody';
import 'nodelist-foreach-polyfill';
import StateManager from './utils/statemanager.es6';

/*
Components
 */
import GlobalHeader from './components/global-header.es6';
import GlobalNav from './components/global-nav.es6';

/*
Modules
 */
import ModuleSample from './modules/module-sample.es6';
import VideoPlayer from './modules/video-player.es6';
import SwiperModule from './modules/swiper-module.es6';
import RelatedContentModule from './modules/related-content-slider.es6';
import Accordion from './modules/accordion.es6';
import FeaturedContentSlider from './modules/featured-content-slider.es6';
import JumboHeroSlider from './modules/jumbo-hero-slider.es6';
import TabbedContentSlider from './modules/tabbed-content-slider.es6';


picturefill();
svg4everybody();


let Components = {
    GlobalHeader,
    GlobalNav
};
let Modules = {
    ModuleSample,
    VideoPlayer,
    SwiperModule,
    Accordion,
    FeaturedContentSlider,
    JumboHeroSlider,
    TabbedContentSlider,
	RelatedContentModule
};


function loadComponent(name, options){
    if(typeof Components[name] !== 'undefined'){
        return new Components[name](options, StateManager);
    }  else {
        console.error('Component load error - ', name);
        return false;
    }
}
function loadModule(name, elem, options){
    if(typeof Modules[name] !== 'undefined'){
        return new Modules[name](elem, options);
    }  else {
        console.error('Module load error - ', name);
        return false;
    }
}


window.Mjolnir = {
    loadModule,
    loadComponent,
    Components: [],
};


Mjolnir.Components.GlobalHeader = new Mjolnir.loadComponent('GlobalHeader');
Mjolnir.Components.GlobalNav = new Mjolnir.loadComponent('GlobalNav');
StateManager.run();
