import extend from 'deep-extend';
import Swiper from 'swiper';

export default class {

    constructor(elem, options) {
        const defaults= {
            swiperContainer: '.related-content .swiper-container',
            swiperOptions: options
        };

        this.options = extend({}, defaults, options);

        this.swiper = null;
        
        this.$elem = elem;

		// Todo: Replace with SSM 
		// No need to initialized Swiper for Desktop
        if(window.innerWidth > 768){
			return;
        } 


        /*
        Init main swiper
         */
        this.$swiperContainer = this.$elem.querySelector(this.options.swiperContainer);

        let defaultSwiperOptions = this.options.swiperOptions;

        this.swiper = new Swiper(this.$swiperContainer, defaultSwiperOptions);
    }
}

