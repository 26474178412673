import extend from 'deep-extend';

export default class {

    constructor(elem, options) {
        const defaults= {
            testString: null
        };

        this.options = extend({}, defaults, options);

        this.$elem = elem;
        //
        this.sampleMethod();
        this.bindEvents();

        // console.log(this);
        //
        // if (this.options.activeCategory){
        //     this.swipeToCategory(this.options.activeCategory)
        // }
    }

    sampleMethod(){
        // console.log('sample method');
        console.log(this.options.testString);
    }


    bindEvents(){
        // console.log(this);
    }
}

