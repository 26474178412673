import extend from 'deep-extend';

export default class {

    constructor(options, statemanager) {
        const defaults = {
            btnAccordionTrigger: '.btn-accordion-trigger',
            subNav: '.sub-nav',
            accordionAnimateSpeed: 300
        };

        this.state = {
            open: false
        };

        this.options = extend({}, defaults, options);
        this.$elem = document.querySelector('#global-nav');

        if (!this.$elem){
            return;
        }

        /*
         Define nodes / elements
         */
        let self = this;
        this.$btnAccordionTrigger = this.$elem.querySelectorAll(this.options.btnAccordionTrigger);
        this.activeSubNav = null;
        this.activeBtn = null;

        /*
        Bind statemanager events as required
         */
        statemanager.addOnEnter('mobile', function () {
            self.initSubNavAccordions();
        });

        statemanager.addOnEnter('desktop', function () {
            self.resetSubNavAccordions();
        });
    }

    openNav(){
        this.$elem.classList.add('open');
        this.state.open = true;
    }

    closeNav(){
        if (this.$elem.classList.contains('open')) {
            this.$elem.classList.remove('open');
            this.state.open = false;
        }

        setTimeout(() => this.closeSubNavAccordion(this.activeSubNav));
    }

    toggleNav(){
        if (this.state.open === false){
            this.openNav();
        } else {
            this.closeNav();
        }
    }

    openSubNavAccordion(targetSubNav, clickedBtn){
        let openHeight = targetSubNav.getAttribute('data-open-height');
        targetSubNav.setAttribute('style', 'height: '+openHeight+'px');
        clickedBtn.classList.add('active');
        this.activeSubNav = targetSubNav;
        this.activeBtn = clickedBtn;
    }

    closeSubNavAccordion(targetSubNav, clickedBtn){
        if(targetSubNav){
            targetSubNav.setAttribute('style', 'height: 0px');
            this.activeSubNav = null;
        }

        if(clickedBtn){
            clickedBtn.classList.remove('active');
            this.activeBtn = null;
        }
    }

    toggleSubNavAccordion(targetSubNav, clickedBtn){

        if (this.activeSubNav !== null){

            let isTargetActive = targetSubNav.isEqualNode(this.activeSubNav);
            if (isTargetActive === true) {
                this.closeSubNavAccordion(targetSubNav, clickedBtn);
            } else {
                this.closeSubNavAccordion(this.activeSubNav, this.activeBtn);
                this.openSubNavAccordion(targetSubNav, clickedBtn);
            }
        } else {
            this.openSubNavAccordion(targetSubNav, clickedBtn);
        }
    }


    initSubNavAccordions(){
        let self = this;
        this.$btnAccordionTrigger.forEach(function (trigger, index) {

            let $subNav = trigger.nextElementSibling;

            if ($subNav){

                let subNavDefaultHeight = $subNav.scrollHeight;

                $subNav.setAttribute('data-open-height', subNavDefaultHeight);
                $subNav.setAttribute('style', 'height: 0px');

                trigger.addEventListener('click', ()=>{
                    self.toggleSubNavAccordion($subNav, trigger)
                });

            } else {
                return;
            }
        });
    }

    resetSubNavAccordions(){
        this.activeSubNav = null;
        this.$btnAccordionTrigger.forEach(function (trigger, index) {

            let $subNav = trigger.nextElementSibling;

            if ($subNav){
                $subNav.setAttribute('style', 'height: auto');

            } else {
                return;
            }
        });
    }
}
