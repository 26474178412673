import extend from 'deep-extend';
import Swiper from 'swiper';

export default class {

    constructor(elem, options) {
        const defaults= {
            swiperSM: '.swiper-sm',
            swiperLG: '.swiper-lg',
            swiperSMOptions: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            swiperLGOptions: {
				 pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
			},
            swiperSlide: '.swiper-slide',
            contentTile: '.content-tile'
        };

        this.options = extend({}, defaults, options);

        this.swiperSM = null;
        this.swiperLG = null;

        /*
        Define nodes / elements
        */
        this.$elem = elem;
        this.$swiperContainerSM = this.$elem.querySelector(this.options.swiperSM);
        this.$swiperContainerLG = this.$elem.querySelector(this.options.swiperLG);
        this.$swiperSlides = this.$elem.querySelectorAll(this.options.swiperSlide);
        this.$contentTilesLG = this.$swiperContainerLG.querySelectorAll(this.options.contentTile);


        /*
        Init Swipers
         */
        this.swiperSM = new Swiper(this.$swiperContainerSM, this.options.swiperSMOptions);
        this.swiperLG = new Swiper(this.$swiperContainerLG, this.options.swiperLGOptions);

        /*
        Function inits
        */
        this.bindEvents();
    }

    bindEvents(){
        /*
        Event binding
         */
        var self = this;
        this.$contentTilesLG.forEach(function (elem, index) {

            elem.addEventListener('mouseenter', ()=>{

                //Add class to slide itself
                elem.classList.add('tile--hover');

                //Add class to module parent
                self.$elem.classList.add('parent--hover');
            });

            elem.addEventListener('mouseleave', ()=>{

                //Remove class to slide itself
                if(elem.classList.contains('tile--hover')) {
                    elem.classList.remove('tile--hover');
                }

                //Remove class to module parent
                if(self.$elem.classList.contains('parent--hover')) {
                    self.$elem.classList.remove('parent--hover');
                }
            });
        });
    }
}

