import extend from 'deep-extend';
export default class {

    constructor(elem, options) {
        const defaults= {

            videoPlayer:  null,
            videoElem: '.player-embed',
            btnPlay: '.btn-play-video',
            hideContentOnPlay: false,
            autoplay: false,
            muted: false,
            hideControls: true,
            controls:[
                'play',
                'progress',
                'current-time',
                'mute',
                'volume',
                'captions',
                'settings',
                'pip',
                'airplay',
                'fullscreen'
            ],
        };

        this.options = extend({}, defaults, options);

        this.$elem = elem;

        this.$videoElem = this.$elem.querySelector(this.options.videoElem);
        this.$btnPlay = this.$elem.querySelector(this.options.btnPlay);

        this.initPlayer();
        this.bindEvents();
    }

    initPlayer() {

        this.videoPlayer = new Plyr(this.$videoElem, {
            autoplay: this.options.autoplay,
            muted: this.options.muted,
            controls: this.options.controls,
            hideControls: this.options.hideControls
        });

    };

    toggleContent(){
        if(this.$elem.classList.contains('content-hidden')){
            this.$elem.classList.remove('content-hidden');
        }  else {
            this.$elem.classList.add('content-hidden');
        }
    }

    playVideo() {
        this.videoPlayer.play();
        if(this.options.hideContentOnPlay === true){
            this.toggleContent();
        }
    };


    bindEvents(){
        if(this.$btnPlay){
            this.$btnPlay.addEventListener('click', ()=>{
                this.playVideo();
            });
        }
    }
}

